<template>
  <section>
   
    <!--列表-->
    <el-table
      :data="users"
      v-loading="listLoading"
      ref="table"
      style="width: 100%"
      class="custom-tbl"
    >
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column type="index" width="80"></el-table-column>
      <el-table-column
        prop="Name"
        label="名称"
        width
        sortable
      ></el-table-column>
      <el-table-column
        prop="Remark"
        label="说明"
        width
        sortable
      ></el-table-column>
      <el-table-column
        prop="CreateName"
        label="操作人"
        width
        sortable
      ></el-table-column>
      <el-table-column
        prop="CreateTime"
        label="操作时间"
        width
        sortable
      ></el-table-column>
    
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="50"
        :total="total"
        style="float: right"
      ></el-pagination>
    </el-col>

  </section>
</template>

<script> 
import {
  getSysOperatePage
} from "../../apis/apis"; 
import routes from "../../router/index";


export default { 
  data() {
    return {
      filters: {
        name: "",
      },
      buttonList: [],
      options: [],
      users: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      total: 0,
      page: 1,
      listLoading: false,
      sels: [], //列表选中列
      currentRow: null,
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getSysOperate();
    },
    //获取列表
    getSysOperate() {
      console.log(routes.history.current);
      let _this = this;
      let para = {
        page: this.page,
        key: this.filters.name,
      };
      this.listLoading = true;
      getSysOperatePage(para).then((res) => {
        this.total = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.listLoading = false;
      });
    },
  },
  mounted() {
    this.getSysOperate(); 
  },
};
</script>

<style scoped>
.custom-tbl /deep/ .has-gutter .el-checkbox {
  display: none;
}
</style>
